import "./Faq.css";

const Faq = () => {
  return (
    <div className="faq-pg">
      <div className="faq-page">
        <div className="container ">
          <div className="row">
            <div className="about-howto-create-token ">
              <h4>General Questions</h4>
            </div>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {/* 1 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-coll-1"
                    aria-expanded="false"
                    aria-controls="flush-coll-1"
                  >
                    What is PowDex?
                  </button>
                </h2>
                <div
                  id="flush-coll-1"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    PowDex is a decentralized exchange (DEX) built on the
                    EthereumPoW (ETHW) blockchain. It allows users to trade ETHW
                    and ERC-20 tokens without intermediaries. Using automated
                    market maker (AMM) technology, PowDex provides a trustless,
                    secure, and transparent trading experience.
                  </div>
                </div>
              </div>
              {/* 2 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-coll-2"
                    aria-expanded="false"
                    aria-controls="flush-coll-2"
                  >
                    What makes PowDex different from other DEXs?
                  </button>
                </h2>
                <div
                  id="flush-coll-2"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    PowDex is optimized for the ETHW blockchain, offering low
                    transaction fees and high scalability. It is tailored for
                    ETHW and ERC-20 token liquidity pairs, empowering users in
                    the ETHW ecosystem to trade seamlessly.
                  </div>
                </div>
              </div>
              {/* 3 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-coll-3"
                    aria-expanded="false"
                    aria-controls="flush-coll-3"
                  >
                    How does PowDex work?
                  </button>
                </h2>
                <div
                  id="flush-coll-3"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    PowDex leverages smart contracts to facilitate token swaps
                    and liquidity provision. It operates through liquidity pools
                    where prices are determined algorithmically based on token
                    reserves.
                  </div>
                </div>
              </div>
              {/* 4 */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-coll-4"
                    aria-expanded="false"
                    aria-controls="flush-coll-4"
                  >
                    Why use PowDex instead of centralized exchanges?
                  </button>
                </h2>
                <div
                  id="flush-coll-4"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        Non-Custodial: Users have full control over their funds.
                      </li>
                      <li>
                        Transparent: All trades and liquidity data are publicly
                        verifiable on the ETHW blockchain.
                      </li>

                      <li>
                        Decentralized: PowDex eliminates the need for
                        intermediaries, reducing risks of hacks and censorship.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6 col-12">
              <div className="about-howto-create-token ">
                <h4>Using PowDex</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow1"
                      aria-expanded="false"
                      aria-controls="pow1"
                    >
                      How can I start trading on PowDex?
                    </button>
                  </h2>
                  <div
                    id="pow1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          Connect an ETHW-compatible wallet (e.g., MetaMask).
                        </li>
                        <li>
                          Select the token pair you want to trade (e.g.,
                          ETHW/USDT).
                        </li>

                        <li>
                          Enter the amount to swap and confirm the transaction.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-2"
                      aria-expanded="false"
                      aria-controls="pow-2"
                    >
                      Which wallets are compatible with PowDex?{" "}
                    </button>
                  </h2>
                  <div
                    id="pow-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li> PowDex supports wallets like MetaMask wallet.</li>{" "}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 3 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-3"
                      aria-expanded="false"
                      aria-controls="pow-3"
                    >
                      What tokens can I trade on PowDex?
                    </button>
                  </h2>
                  <div
                    id="pow-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          PowDex supports ETHW and any ERC-20 tokens deployed on
                          the ETHW blockchain.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 4 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-4"
                      aria-expanded="false"
                      aria-controls="pow-4"
                    >
                      What fees are involved when using PowDex?
                    </button>
                  </h2>
                  <div
                    id="pow-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Users pay:
                      <ul>
                        <li>
                          Trading Fee: A small percentage of the trade,
                          distributed to liquidity providers.
                        </li>
                        <li>
                          Gas Fee: Transaction fees for the ETHW blockchain.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 5 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#pow-5"
                      aria-expanded="false"
                      aria-controls="pow-5"
                    >
                      Can I use PowDex on mobile devices?
                    </button>
                  </h2>
                  <div
                    id="pow-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          Yes, PowDex is mobile-friendly and can be accessed via
                          a browser or mobile wallets like MetaMask and Trust
                          Wallet.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
                s
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-howto-create-token ">
                <h4>Liquidity Pools and Earnings</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-1"
                      aria-expanded="false"
                      aria-controls="liq-1"
                    >
                      What is a liquidity pool?
                    </button>
                  </h2>
                  <div
                    id="liq-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          A liquidity pool is a smart contract containing two
                          tokens (e.g., ETHW/USDT) that users provide to enable
                          decentralized trading. In return, liquidity providers
                          earn a share of trading fees.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
                {/* 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-2"
                      aria-expanded="false"
                      aria-controls="liq-2"
                    >
                      How can I add liquidity on PowDex?
                    </button>
                  </h2>
                  <div
                    id="liq-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>Navigate to the “Liquidity” section.</li>
                        <li>
                          Choose a token pair (e.g., ETHW and an ERC-20 token).
                        </li>

                        <li>
                          Provide equal values of both tokens and confirm the
                          transaction.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 3 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-3"
                      aria-expanded="false"
                      aria-controls="liq-3"
                    >
                      What rewards do liquidity providers earn?
                    </button>
                  </h2>
                  <div
                    id="liq-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          Liquidity providers earn a share of transaction fees
                          proportional to their contribution to the pool.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 4 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-4"
                      aria-expanded="false"
                      aria-controls="liq-4"
                    >
                      Can I remove my liquidity anytime?
                    </button>
                  </h2>
                  <div
                    id="liq-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          Yes, you can withdraw your liquidity along with any
                          accumulated fees at any time by removing your
                          liquidity from the pool.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 5 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#liq-5"
                      aria-expanded="false"
                      aria-controls="liq-5"
                    >
                      Why is ETHW required in all pools?
                    </button>
                  </h2>
                  <div
                    id="liq-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          ETHW serves as the base asset, ensuring consistent
                          pricing and ease of interoperability across all token
                          pairs.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          {/* governance  */}
          <div className="row mt-4">
            <div className="col-lg-6 col-12">
              <div className="about-howto-create-token ">
                <h4>Governance and Tokenomics</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#gov-1"
                      aria-expanded="false"
                      aria-controls="gov-1"
                    >
                      Does PowDex have a native token?
                    </button>
                  </h2>
                  <div
                    id="gov-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          PowDex plans to introduce a native governance token
                          (e.g., POWDEX). This token will allow holders to
                          participate in decisions about the protocol and earn
                          rewards through staking.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#gov-2"
                      aria-expanded="false"
                      aria-controls="gov-2"
                    >
                      What is PowDex governance?
                    </button>
                  </h2>
                  <div
                    id="gov-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          {" "}
                          PowDex governance enables token holders to propose and
                          vote on protocol upgrades, fee adjustments, and new
                          feature implementations.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-howto-create-token ">
                <h4>Troubleshooting</h4>
              </div>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#tro-1"
                      aria-expanded="false"
                      aria-controls="tro-1"
                    >
                      Why did my transaction fail?
                    </button>
                  </h2>
                  <div
                    id="tro-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Transactions can fail due to:
                      <ul>
                        <li>Insufficient gas fees.</li>
                        <li>Low slippage tolerance.</li>
                        <li>Insufficient liquidity in the selected pool.</li>
                      </ul>
                    </div>
                  </div>
                </div>{" "}
                {/* 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#tro-2"
                      aria-expanded="false"
                      aria-controls="tro-2"
                    >
                      Why does my token swap show "Insufficient Liquidity"?
                    </button>
                  </h2>
                  <div
                    id="tro-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li>
                          This occurs when the selected pool lacks enough tokens
                          for the trade. You can either reduce the trade size or
                          choose a different pool.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
