const FooterSub = () => {
  return (
    <div className="section-bg">
      <div className="container">
        <div className="footer-pg footer-sub-pg">
          {" "}
          {/* <div style={{ textAlign: "center" }}>
            © All rights reserved 2024 - PoWDex.io
          </div> */}
          <div className="footer-flex bg-none ">
            {" "}
            <div>
              <i className="fa-brands fa-x-twitter fa-lg text-white"></i>
            </div>
            <div style={{ color: "grey" }}>
              © All rights reserved 2024 - PoWDex.io
            </div>
            {/* <div>
              <span className="me-2">Trademark Policy</span>
              <span> Privacy Policy</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSub;
