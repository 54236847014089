import Banner from "../Banner/Banner";
import Footer from "../Footer/Footer";
import Section1 from "../Section1/Section1";
import Section2 from "../Section2/Section2";
import Section3 from "../Section3/Section3";
import Faq from "../Pages/Faq/Faq";
import "./Home.css";

const Home = () => {
  return (
    <>
      <Banner />
      {/* <Section1 />
      <Section2 />
      <Section3 /> */}
      <Faq />
      {/* <Footer /> */}
    </>
  );
};

export default Home;
