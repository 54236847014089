import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FaAngleUp, FaAngleDown, FaArrowDown, FaArrowUp } from "react-icons/fa";
import "./TableComponent.css";

const columns = [
  { id: "name", label: "Token Name", minWidth: 170 },
  { id: "code", label: "Price", minWidth: 100 },
  {
    id: "population",
    label: "1 Hour",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "1 Day",
    minWidth: 100,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Volume",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  { id: "fdv", label: "FDV", minWidth: 100, align: "right" },
];

function createData(name, code, population, size, price) {
  const density = population / size;
  const fdv = price * 100; // Arbitrary FDV calculation
  const trend = price % 2 === 0 ? "up" : "down"; // Random trend based on price
  return { name, code, population, size, density, trend, fdv };
}

// Sample rows
const rows = [
  createData("India", "IN", 1324171354, 3287263, 100),
  createData("China", "CN", 1403500365, 9596961, 150),
  createData("Italy", "IT", 60483973, 301340, 200),
  createData("United States", "US", 327167434, 9833520, 75),
  createData("Canada", "CA", 37602103, 9984670, 120),
  createData("Australia", "AU", 25475400, 7692024, 90),
  createData("Germany", "DE", 83019200, 357578, 110),
  createData("Ireland", "IE", 4857000, 70273, 130),
  createData("Mexico", "MX", 126577691, 1972550, 145),
  createData("Japan", "JP", 126317000, 377973, 85),
  createData("France", "FR", 67022000, 640679, 70),
  createData("United Kingdom", "GB", 67545757, 242495, 95),
  createData("Russia", "RU", 146793744, 17098246, 50),
  createData("Nigeria", "NG", 200962417, 923768, 170),
  createData("Brazil", "BR", 210147125, 8515767, 60),
];

export default function TableComponent() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(columnId);
  };

  const sortedRows = React.useMemo(() => {
    if (!orderBy) return rows;
    return [...rows].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
  }, [rows, orderBy, order]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }} className="tbg-scroll">
        <Table stickyHeader aria-label="sortable table" className="table-bg">
          <TableHead className="thead-bg">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, cursor: "pointer" }}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    order === "asc" ? (
                      <FaArrowUp color="#fff" />
                    ) : (
                      <FaArrowDown color="#fff" />
                    )
                  ) : (
                    ""
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="tbody-bg">
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.id === "code") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}{" "}
                          {row.trend === "up" ? (
                            <FaAngleUp color="green" />
                          ) : (
                            <FaAngleDown color="red" />
                          )}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className="pagination-table"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
